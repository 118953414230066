import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { Injectable } from '@angular/core';

import { IMAGES } from './gallery-data';

@Injectable()

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];

  houseImages: NgxGalleryImage[] = this.getImages('trengwainton');
  cottageImages: NgxGalleryImage[] = this.getImages('cottage');
  khayalethuImages: NgxGalleryImage[] = this.getImages('khayalethu');
  springImages: NgxGalleryImage[] = this.getImages('spring');
  summerImages: NgxGalleryImage[] = this.getImages('summer');
  autumnImages: NgxGalleryImage[] = this.getImages('autumn');
  winterImages: NgxGalleryImage[] = this.getImages('winter');

  constructor() { }

  getImages(category) {
    const object = [];
    const url = 'assets/images/gallery/';
    IMAGES.forEach(data => {
    if (data.category === category) {
        const tmp = {
          small: url + category + '/' + data.image.url,
          medium: url + category + '/' + data.image.url,
          big: url + category + '/' + data.image.url,
          description: data.image.description
        };
        object.push(tmp);
      }
    });
    // console.log(object);
    return object;
  }

  ngOnInit(): void {

    this.galleryOptions = [
            {
                thumbnails: true,
                width: '100%',
                height: '90vh',
                thumbnailsColumns: 6,
                previewCloseOnClick: true,
                previewZoom: true,
                previewFullscreen: true,
                previewKeyboardNavigation: true,
                imageDescription: true,
                imageSwipe: true,
                previewSwipe: true,
                // thumbnailsSwipe: true,
                imageArrowsAutoHide: false,
                imageArrows: true,
                imageAnimation: NgxGalleryAnimation.Slide
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '91vh',
                imagePercent: 80,
                preview: true
          },
            // max-width 400
            {
                breakpoint: 400,
                preview: true
            }
        ];

  }

}
