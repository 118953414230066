import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { fadeInOutAnimation } from './app-routing.animation';

const fadeInTimeMS = 300;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ],
  animations: [
    fadeInOutAnimation
  ]
})
export class AppComponent implements AfterViewInit {

  constructor(private myElement: ElementRef) {}

  title = 'Trengwainton';

  ngAfterViewInit () {
    const appRootRef = this.myElement; // Necesarry because after setTimeout, 'this' becomes window
    setTimeout(function() {
      appRootRef.nativeElement.previousElementSibling.remove();
    }, fadeInTimeMS);
  }
}
