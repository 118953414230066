import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
// import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import 'hammerjs';

import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatNativeDateModule, GestureConfig } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxGalleryModule } from 'ngx-gallery';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AccommodationComponent } from './accommodation/accommodation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { ContactComponent } from './contact/contact.component';
import { BookingComponent } from './booking/booking.component';
import {  } from '@angular/material';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { MainHouseComponent } from './accommodation/main-house/main-house.component';
import { CottageComponent } from './accommodation/cottage/cottage.component';
import { KhayalethuComponent } from './accommodation/khayalethu/khayalethu.component';

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent, data: { title: 'Home' } },
  { path: 'about', component: AboutComponent, data: { title: 'About' } },
  { path: 'accommodation', component: AccommodationComponent, data: { title: 'Accommodation' } },
  { path: 'accommodation/main-house', component: MainHouseComponent, data: { title: 'Main House' } },
  { path: 'accommodation/cottage', component: CottageComponent, data: { title: 'Cottage' } },
  { path: 'accommodation/khayalethu', component: KhayalethuComponent, data: { title: 'Khayalethu Cottage' } },
  { path: 'gallery', component: GalleryComponent, data: { title: 'Gallery' } },
  { path: 'contact', component: ContactComponent, data: { title: 'Contact' } },
  { path: 'booking', component: BookingComponent, data: { title: 'Booking' } },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    AccommodationComponent,
    GalleryComponent,
    ContactComponent,
    BookingComponent,
    PageNotFoundComponent,
    MainHouseComponent,
    CottageComponent,
    KhayalethuComponent
  ],
  imports: [
    BrowserModule,
    // HttpModule,
    HttpClientModule,
    FlexLayoutModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    BrowserAnimationsModule,
    MatMenuModule, MatSelectModule, MatButtonModule, MatToolbarModule, MatIconModule, MatTooltipModule, MatInputModule,
    MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, MatSliderModule, MatTabsModule, MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGalleryModule
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-ZA'},
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
