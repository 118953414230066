import { Image } from './image';

export const IMAGES: Image[] = [
  { category: 'trengwainton', image: { url: 'lounge1.jpg', description: '' } },
  { category: 'trengwainton', image: { url: 'lounge2.jpg', description: '' } },
  { category: 'trengwainton', image: { url: 'varandah1.jpg', description: '' } },
  { category: 'trengwainton', image: { url: 'room1.jpg', description: '' } },
  { category: 'trengwainton', image: { url: 'kitchen1.jpg', description: '' } },
  { category: 'trengwainton', image: { url: 'kitchen2.jpg', description: '' } },
  { category: 'trengwainton', image: { url: 'kitchen3.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '1.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '2.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '3.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '4.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '5.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '6.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '7.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '8.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '9.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '10.jpg', description: '' } },
  { category: 'trengwainton', image: { url: '11.jpg', description: '' } },
  // { category: 'cottage', image: { url: 'Bathroom1.jpg', description: '' } },
  // { category: 'cottage', image: { url: 'Bathroom2.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Lounge1.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Bedroom1.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Kitchen1.jpg', description: '' } },
  // { category: 'cottage', image: { url: 'bathroom3.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Lounge2.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Bedroom2.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Outside1.jpg', description: '' } },
  { category: 'cottage', image: { url: 'Patio2.jpg', description: '' } },
  { category: 'cottage', image: { url: '1.jpg', description: '' } },
  { category: 'cottage', image: { url: '2.jpg', description: '' } },
  { category: 'cottage', image: { url: '3.jpg', description: '' } },
  { category: 'cottage', image: { url: '4.jpg', description: '' } },
  { category: 'cottage', image: { url: '5.jpg', description: '' } },
  { category: 'cottage', image: { url: '6.jpg', description: '' } },
  { category: 'cottage', image: { url: '7.jpg', description: '' } },
  { category: 'cottage', image: { url: '8.jpg', description: '' } },
  { category: 'cottage', image: { url: '9.jpg', description: '' } },
  // { category: 'cottage', image: { url: 'Patio1.jpg', description: '' } },
  { category: 'winter', image: { url: 'winter.jpg', description: '' } },
  { category: 'winter', image: { url: '1d07.jpg', description: '' } },
  { category: 'winter', image: { url: 'winter1.jpg', description: '' } },
  { category: 'winter', image: { url: 'winter2.jpg', description: '' } },
  { category: 'winter', image: { url: '1.jpg', description: '' } },
  { category: 'winter', image: { url: '2.jpg', description: '' } },
  { category: 'winter', image: { url: '3.jpg', description: '' } },
  { category: 'winter', image: { url: '4.jpg', description: '' } },
  { category: 'winter', image: { url: '5.jpg', description: '' } },
  { category: 'winter', image: { url: '6.jpg', description: '' } },
  { category: 'winter', image: { url: '7.jpg', description: '' } },
  { category: 'winter', image: { url: '8.jpg', description: '' } },
  { category: 'winter', image: { url: '9.jpg', description: '' } },
  { category: 'winter', image: { url: '10.jpg', description: '' } },
  { category: 'winter', image: { url: '11.jpg', description: '' } },
  { category: 'winter', image: { url: '12.jpg', description: '' } },
  { category: 'summer', image: { url: 'treng.jpg', description: '' } },
  { category: 'summer', image: { url: 'summer1.jpg', description: '' } },
  { category: 'summer', image: { url: 'summer2.jpg', description: '' } },
  { category: 'summer', image: { url: '1.jpg', description: '' } },
  { category: 'summer', image: { url: '2.jpg', description: '' } },
  { category: 'summer', image: { url: '3.jpg', description: '' } },
  { category: 'summer', image: { url: '4.jpg', description: '' } },
  { category: 'summer', image: { url: '5.jpg', description: '' } },
  { category: 'summer', image: { url: '6.jpg', description: '' } },
  { category: 'autumn', image: { url: 'pathway.jpg', description: '' } },
  { category: 'autumn', image: { url: 'shrine.jpg', description: '' } },
  { category: 'autumn', image: { url: 'sundial.jpg', description: '' } },
  { category: 'autumn', image: { url: '161e.jpg', description: '' } },
  { category: 'autumn', image: { url: '1c31.jpg', description: '' } },
  { category: 'autumn', image: { url: '1.jpg', description: '' } },
  { category: 'autumn', image: { url: '2.jpg', description: '' } },
  { category: 'autumn', image: { url: '3.jpg', description: '' } },
  { category: 'spring', image: { url: 'IMG_4259.jpg', description: '' } },
  { category: 'spring', image: { url: '1.jpg', description: '' } },
  { category: 'spring', image: { url: '2.jpg', description: '' } },
  { category: 'spring', image: { url: '3.jpg', description: '' } },
  { category: 'spring', image: { url: '4.jpg', description: '' } },
  { category: 'spring', image: { url: '5.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '1.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '2.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '3.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '4.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '5.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '6.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '7.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '8.jpg', description: '' } },
  { category: 'khayalethu', image: { url: '9.jpg', description: '' } },
];
