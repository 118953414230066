import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.scss']
})
export class BookingComponent implements OnInit {

  calendar: any[];
  startDate: Date;
  today = new Date();
  bookingForm: FormGroup;
  maxDays = 20;
  days = '1';
  message = '';
  sent = false;
  disabledSubmitButton = true;

  units: {
    [key: string]: {} } = {
      'house': {
        name: 'Main House',
        min: 2,
        max: 10,
        in_rate: 1700,
        out_rate: 1400
      },
      'cottage': {
        name: 'Cottage',
        min: 1,
        max: 2,
        in_rate: 1200,
        out_rate: 900
      }
  };

  @HostListener('input') oninput() {

    if (this.bookingForm.valid) {
      this.disabledSubmitButton = false;
    } else {
      this.disabledSubmitButton = true;
    }
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'Email address is required' :
        this.email.hasError('email') ? 'Not a valid email' :
        this.email.hasError('pattern') ? 'Not a valid email' :
            '';
  }

  getGuestErrorMessage() {
    return this.guests.hasError('required') ? '# of Guests required' :
        this.guests.hasError('min') ? 'Minumum 1 Guest' :
        this.guests.hasError('max') ? 'Maximum 10 Guests' :
            '';
  }

  constructor(private http: HttpClient, private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit() {}

  get selectedUnit(): FormControl {
    return this.bookingForm.get('selectedUnit') as FormControl;
  }

  get date(): FormControl {
    return this.bookingForm.get('date') as FormControl;
  }

  get outdate(): FormControl {
    return this.bookingForm.get('outdate') as FormControl;
  }

  get guests(): FormControl {
    return this.bookingForm.get('guests') as FormControl;
  }

  get name(): FormControl {
    return this.bookingForm.get('name') as FormControl;
  }

  get tel(): FormControl {
    return this.bookingForm.get('tel') as FormControl;
  }

  get email(): FormControl {
    return this.bookingForm.get('email') as FormControl;
  }

  get comments(): FormControl {
    return this.bookingForm.get('comments') as FormControl;
  }

  createForm() {
    this.bookingForm = this.fb.group({
      selectedUnit: ['', [
        Validators.required
      ]],
      date: ['', [
        Validators.required
      ]],
      outdate: ['', [
        Validators.required
      ]],
      guests: ['', [
        Validators.required,
        Validators.min(1),
        Validators.max(10)
      ]],
      name: ['', [
        Validators.required
      ]],
      tel: ['', [
        Validators.required
      ]],
      email: ['', [
        Validators.required,
        Validators.email,
        Validators.pattern('[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}')
      ]],
      comments: ['', [

      ]]
    });
  }
   getIcal(value: string) {
     this.bookingForm.controls.date.disable();
     this.bookingForm.controls.outdate.disable();
     this.bookingForm.controls.guests.disable();
     const url = 'https://www.trengwainton.co.za/the_server_files/calendar.php?unit=' + value;
      this.http.get(url).forEach(
        data => {
          this.calendar = this.makeCalendar(data);
        }
      );
    }

    makeCalendar(json: any) {
      const calendar = [];
      json.forEach(
        data => {
          for (let x = 0; x < data.days; x++) {
            const date = new Date(data.startdate);
            date.setHours(0, 0, 0, 0);
            const pushdate = date.setDate(date.getDate() + x);
            // const fulldate = new DatePipe('en-ZA').transform(pushdate, 'yyyy-MM-dd');
            calendar.push(pushdate);
          }
        }
      );
      this.bookingForm.controls.date.enable();
      this.bookingForm.controls.outdate.enable();
      this.bookingForm.controls.guests.enable();
      this.bookingForm.controls.date.setValue('');
      this.bookingForm.controls.outdate.setValue('');
      this.bookingForm.controls.guests.setValue('');
      return calendar;
    }

     getAvailableDays(bookingStartDate: Date) {
       const bookings = this.calendar;
       const date = new Date(bookingStartDate);
       date.setDate(date.getDate() - 1);
       let n = 0;
       for (let x = 0; x < this.maxDays; x++) {
         const theDate = date;
         theDate.setDate(date.getDate() + 1);
         if (theDate.getTime() && bookings.indexOf(theDate.getTime()) > -1) {
           break;
         } else {
           n++;
         }
       }
       return n;
     }

      myFilter = (d: Date): boolean => {
        // const date = new DatePipe('en-ZA').transform(d, 'yyyy-MM-dd');
        return this.calendar.indexOf(d.getTime()) < 0;
      }

      book() {
        this.bookingForm.disable();
        const myheader = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        let body = new HttpParams();
        const startdate = new Date(this.bookingForm.get('date').value);
        const enddate = new Date(this.bookingForm.get('outdate').value);
        const date = new DatePipe('en-ZA').transform(startdate, 'fullDate');
        const outdate = new DatePipe('en-ZA').transform(enddate, 'fullDate');
        const days = (enddate.getTime() - startdate.getTime()) / (1000 * 60 * 60 * 24) + '';
        body = body.set('email', this.bookingForm.get('email').value);
        body = body.set('unit', this.bookingForm.get('selectedUnit').value);
        body = body.set('date', date);
        body = body.set('outdate', outdate);
        body = body.set('guests', this.bookingForm.get('guests').value);
        body = body.set('days', days);
        body = body.set('name',  this.bookingForm.get('name').value);
        body = body.set('tel',  this.bookingForm.get('tel').value);
        body = body.set('comments',  this.bookingForm.get('comments').value);

        const url = 'https://www.trengwainton.co.za/the_server_files/send_booking.php';
         this.http.post(url, body, { headers: myheader }).subscribe(
         (res: Response) => {
           document.getElementById('message').innerHTML = res['sentence'];
           this.sent = true;
           this.disabledSubmitButton = true;
         }
      );
      }

      dayPlusOne(date: Date) {
        const thedate = new Date(date);
        thedate.setDate(thedate.getDate() + 1);
        const newdate = new Date(thedate);
        return newdate;
      }

      getToDate(date: Date) {
        const daysavail = this.getAvailableDays(this.date.value);
        const thedate = new Date(date);
        thedate.setDate(thedate.getDate() + daysavail);
        const newdate = new Date(thedate);
        return newdate;
      }

      setDays(event: any) {
        this.days = event.value;
      }

      getEstimate() {
        return 'R500';
      }
}
