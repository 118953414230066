import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cottage',
  templateUrl: './cottage.component.html',
  styleUrls: ['./cottage.component.scss']
})
export class CottageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
